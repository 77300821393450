/*
  Modded from: 
  
  Author: Mihovil Ilakovac (infomiho)
  Used: javascript + underscore.js (for templating), Foundation 5 (design)
  
  It's based on a simple idea, to minimize requests to the server and only send the final cart to the server for evaluation and payment.

*/



const cartId = "cart";
const viewModes = {
    updateCounter:'updateCounter',
    updateItemList:'updateItemList'
}
let activeMode = viewModes.updateCounter

const localAdapter = {

    saveCart: function (object) {

        const stringified = JSON.stringify(object);
        localStorage.setItem(cartId, stringified);
        return true;

    },
    getCart: function () {
        return JSON.parse(localStorage.getItem(cartId));
    },
    clearCart: function () {

        localStorage.removeItem(cartId);

    }

};

var ajaxAdapter = {

    saveCart: function (object) {

        const stringified = JSON.stringify(object);
        // do an ajax request here

    },
    getCart: function () {

        // do an ajax request -- recognize user by cookie / ip / session
        return JSON.parse(data);

    },
    clearCart: function () {

        //do an ajax request here

    }

};

const storage = localAdapter;

const helpers = {

    getHtml: function (id) {
        const item = document.getElementById(id)
        if(item){
            return item.innerHTML
        }
        return undefined
    },
    setHtml: function (id, html) {
        const item = document.getElementById(id)
        if(item){
            item.innerHTML = html;
            return true;
        }
        return false;

    },
    itemData: function (object) {
        let count = object.getAttribute("data-count"),
            patt = new RegExp("^[1-9]([0-9]+)?$");
        count = (patt.test(count) === true) ? parseInt(count) : 1;

        let item = {
            checked: true,
            name: object.getAttribute('data-name'),
            price: object.getAttribute('data-price') ,
            id: object.getAttribute('data-id'),
            count: count,
            total: parseFloat(object.getAttribute('data-price')) * count

        };
        return item;

    },
    updateView: function () {
        const items = cart.getItems()
        this.setHtml('cartCounter','('+ cart.selectedItemCount() +')')
        if(activeMode == viewModes.updateItemList){
            
            const template = this.getHtml('cartT')
            const compiled = _.template(template)
            this.setHtml( 'cartItems', compiled({items: items}) )    
            this.updateTotal()
            if(feather){
                feather.replace()
            }
            if(resetEventListeners){
                resetEventListeners()
            }
        }
    },
    emptyView: function () {
        if(activeMode == viewModes.updateItemList){
            this.setHtml('cartItems', '<p>Nothing to see here</p>');
        }
        this.updateTotal();

    },
    updateTotal: function () {
        this.setHtml('cartCounter','('+ cart.selectedItemCount() +')')
        this.setHtml('totalPrice', cart.total.toFixed(2) + '$');
        this.setHtml('selectedTotal', cart.total.toFixed(2) + '$');
        
    }

};

const cart = {

    count: 0,
    total: 0,
    items: [],
    getItems: function () {

        return this.items;

    },
    setItems: function (items) {

        this.items = items;
        this.calculateTotals();

    },
    selectedItemCount : function(item){
        return this.items.filter(x=>x.checked==true).length
    },
    setChecked(id, checked){
        for(let i=0;i<this.items.length;i++){
            if(this.items[i].id == id && checked != this.items[i].checked){
                this.items[i].checked = checked
            }
        }
        this.calculateTotals()
        storage.saveCart(this.items);
        helpers.updateTotal();
        
    },
    clearItems: function () {

        this.items = [];
        this.total = 0;
        storage.clearCart();
        this.calculateTotals();
        helpers.emptyView();

    },
    removeItem: function(id){
        let idx = -1
        for(let i=0;i<this.items.length;i++){
            if(this.items[i].id == id){
                idx = i
            }
        }
        if(idx!=-1){
            this.items.splice(idx,1)
        }
        this.calculateTotals()
        storage.saveCart(this.items)
        helpers.updateView()
    },
    addItem: function (item) {

        if (this.containsItem(item.id) === false) {

            this.items.push({
                checked: true,
                id: item.id,
                name: item.name,
                price: item.price,
                count: item.count,
                total: item.price * item.count
            });

            this.calculateTotals();

            storage.saveCart(this.items);


        } else {

            this.updateItem(item);

        }
        this.total += item.price * item.count;
        this.count += item.count;
        helpers.updateView();

    },
    calculateTotals: function(){
        this.total = 0.0
        for(let i=0;i<this.items.length;i++){
            this.items[i].total = this.items[i].price * this.items[i].count
            if(this.items[i].checked){
                this.total += this.items[i].total
            }
        }
    },
    containsItem: function (id) {

        if (this.items === undefined) {
            return false;
        }

        for (let i = 0; i < this.items.length; i++) {

            let _item = this.items[i];

            if (id == _item.id) {
                return true;
            }

        }
        return false;

    },
    updateAmount: function (id, amount){
        for(let i=0;i<this.items.length;i++){
            if(this.items[i].id==id){
                this.items[i].count = amount
                this.calculateTotals()
                storage.saveCart(this.items)
                helpers.updateView()
                return
            }
        }
    },
    updateItem: function (object) {

        for (let i = 0; i < this.items.length; i++) {

            let _item = this.items[i];

            if (object.id === _item.id) {

                _item.count = parseInt(object.count);
                _item.price = parseFloat(object.price);
                _item.checked = object.checked
                this.items[i] = _item;
                this.calculateTotals();
                storage.saveCart(this.items);
                helpers.updateView();
            }

        }

    }

};

function resetEventListeners(){

    const selectItemCheckBoxes =  document.getElementsByClassName('selectItemCheckBox')
    for(let cb of selectItemCheckBoxes){
        const targetItemId = cb.getAttribute('data-target-item-id')
        for(let item of cart.items){
            if(item.id==targetItemId && item.checked)
                cb.setAttribute('checked','checked')
        }
    }

    for(let cb of selectItemCheckBoxes){
        cb.addEventListener('change',(event)=>{
            console.debug('set checked')
            const targetItemId = event.target.getAttribute('data-target-item-id')
            cart.setChecked(targetItemId, event.target.checked)
        })
    }

    const removeLinks = document.getElementsByClassName('removeLink')
    for(let rl of removeLinks){
        rl.addEventListener('click',(event)=>{
            event.preventDefault()
            const targetItemId = rl.getAttribute('data-target-item-id')
            cart.removeItem(targetItemId)
        })
    }

    const amountInputs = document.getElementsByClassName('amountInput')
    for (let aInput of amountInputs){
        aInput.addEventListener('change',(event)=>{
            event.preventDefault()
            const targetItemId = aInput.getAttribute('data-target-item-id')
            cart.updateAmount(targetItemId,parseInt(event.target.value))
            console.debug('updated')    
        })
    }
}

document.addEventListener('DOMContentLoaded', function () {
    if(document.getElementById('updateItemList')){
        activeMode = viewModes.updateItemList
    }
    
    if (storage.getCart()) {
        cart.setItems(storage.getCart());
        helpers.updateView();

    } else {

        helpers.emptyView();

    }
    let products = document.querySelectorAll('.product');
    [].forEach.call(products, function (product) {

        product.addEventListener('click', function (e) {
            e.preventDefault()
            const item = helpers.itemData(e.target);
            cart.addItem(item);
        });

    });



    const clearButton = document.querySelector('#clear')
    if(clearButton){
        document.querySelector('#clear').addEventListener('click', function (e) {
            cart.clearItems();
        });
    }
    const purchaseSucceeded = document.getElementById("purchaseSucceeded")
    if(purchaseSucceeded){
        cart.clearItems()
    }

    resetEventListeners()
    const buyButton = document.getElementById('buyButton')
    const buyForm = document.getElementById('buyForm')
    const buyEventListenerFunc = (event)=>{
        event.preventDefault();
        const postUrl = event.target.getAttribute('data-url')
        if(cart.selectedItemCount()==0){
            alert('nothing selected for checkout')
            return
        }
        const items = cart.getItems()
        const selectedItems = []
        for(item of items){
            if(item.checked){
                selectedItems.push(item)
            }
        }
        const itemsInput = document.createElement('input')
        itemsInput.setAttribute('name','items')
        itemsInput.setAttribute('type','hidden')
        itemsInput.setAttribute('value', JSON.stringify(selectedItems))
        const totalInput = document.createElement('input')
        totalInput.setAttribute('name','total')
        totalInput.setAttribute('type','hidden')
        totalInput.setAttribute('value',cart.total)
        buyForm.appendChild(itemsInput)
        buyForm.appendChild(totalInput)
        buyForm.submit()
    }
    if(buyButton){
        buyButton.addEventListener('click',buyEventListenerFunc)
        
    }
    

});
